import React from 'react'

import './Hero.css'

const Hero = ({
  title,
  children,
}) => (
  <div className="Hero">
    <h1>{title}</h1>
    {children}
  </div>
)

export default Hero
